import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Menu from "./pages/Menu";
import Settings from "./pages/Settings";
import Register from "./pages/Register";
import AudioSetting from "./pages/AudioSetting";
import Preview from "./pages/Preview";
import CharginStation from "./pages/ChargingStation"


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/register" element={<Register />} />
        <Route path="/audio-setting" element={<AudioSetting />} />
        <Route path="/charging-station/:accountNumber" element={<CharginStation />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
