import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { Music } from "react-feather";

export default function MusicPlayer({ fingerprintScanSucess, audioSrc }) {
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        // const audio = document.querySelector(".player__audio");
        // audio.play();
        setPlaying(true);
    }, [fingerprintScanSucess]);

    const playAudio = () => {

    }

    const handleToggleAudio = useCallback(() => {
        const audio = document.querySelector(".player__audio");
        if (!playing) {
            audio.play();
            setPlaying(true);
        } else {
            setPlaying(false);
            audio.pause();
        }
        setPlaying(!playing);
    });

    if (!fingerprintScanSucess && !audioSrc) {
        return (
            <audio className="player__audio viewer">
            </audio>
        );
    }

    return (
        <>
            <div id="audio" onClick={handleToggleAudio}>
                {fingerprintScanSucess && (
                    <div className={classnames("audio-wrapper", { mrotate: playing })}>
                        <Music />
                        {!playing && (
                            <div className="icon-cancel">
                                <div className="icon-h" />
                            </div>
                        )}
                    </div>
                )}
                <audio className="player__audio viewer">
                    <source src={audioSrc} type="audio/mpeg" data-trackid={1} />
                    Your browser does not support the audio element.
                </audio>
            </div>
        </>
    );
}
