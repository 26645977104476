import React, { useCallback, useState, useEffect } from "react";
import { User, Heart, Music, Smartphone } from "react-feather";
import "../../assets/css/custom.css";
import { useNavigate } from "react-router-dom";

export default function BottomMenu({ currentScreen, changeScreen }) {
  const navigate = useNavigate();

  // useEffect(() => {
  //     // Hide bottom menu when scroll down
  //     var lastScrollTop;
  //     let navbar = document.getElementById('nav-menu');
  //     window.addEventListener('scroll',function(){
  //         var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //         if(scrollTop > lastScrollTop){
  //             navbar.style.bottom='-80px';
  //         }
  //         else{
  //             navbar.style.bottom='0';
  //         }
  //         lastScrollTop = scrollTop;
  //     });
  // }, [])

  const tabs = [
    {
      key: "preview",
      title: "Xem Trước",
      icon: <Smartphone />,
    },
    {
      key: "settings",
      title: "Nội Dung",
      icon: <Heart />,
    },
    {
      key: "audio-setting",
      title: "Nhạc",
      icon: <Music />,
      badge: "1",
    },
    {
      key: "menu",
      title: "Menu",
      icon: <User />,
      badge: "9+",
    },
  ];

  const getUrl = () => {
    const currentURL = window.location.href;
    const regex = /\/([^\/]+)$/; // regex để lấy phần cuối của URL sau dấu gạch chéo cuối cùng
    const matches = currentURL.match(regex);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return;
  };

  const [currentMenuState, setCurrentMenuState] = useState(getUrl());

  const handleChangeMenuState = useCallback((e, currentMenu) => {
    e.preventDefault();
    setCurrentMenuState(currentMenu);
    navigate(`/${currentMenu}`);
  }, []);

  console.log(currentMenuState, "currentMenuState");

  return (
    <>
      <div className="nav__menu" id="nav-menu">
        <ul className="nav__list">
          {tabs.map((item, index) => (
            <li key={index} className="nav__item">
              <a
                onClick={(e) => handleChangeMenuState(e, item.key)}
                className={
                  "nav__link " +
                  (currentMenuState == item.key ? "active-link" : "")
                }
              >
                {item.icon}
                <span className="nav__name">{item.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
