import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/foating-hearts.json';
import '../../assets/css/animate.css';
import classNames from "classnames";
import FingerPrint from './FingerprintScanner'
import { useState } from 'react';


const SplashScreen = ({visible, scanSucess}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        }
      };
      const [fingerprintScanSucess, setFingerprintScan] = useState(false);
      const handleSucessScanPrint = () => {
        setFingerprintScan(true);
        scanSucess();
      }
    return (
        <div className={classNames('splash-screen z-50 fixed top-0 left-0 w-screen h-screen flex justify-center items-center', {'hide': !visible && fingerprintScanSucess})}>
            {
              visible &&
              <Lottie options={defaultOptions} height={400} width={160} />
            }
            <FingerPrint onSucess={() => handleSucessScanPrint()} />
        </div>
    );
}

export default React.memo(SplashScreen);