import React, { useState, useEffect, useCallback } from "react";
export default function YoutubeInfo({ data }) {

    if (!data.title) {
        return <></>
    }

    function formatDuration(duration) {
        const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
      
        const hours = (parseInt(match[1]) || 0);
        const minutes = (parseInt(match[2]) || 0);
        const seconds = (parseInt(match[3]) || 0);
      
        return `${hours ? hours.toString().padStart(2, '0') + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
      const videoDuration = formatDuration(data.duration);

  return (
    <>
      <div className="youtube-video-info">
        <div className="detail px-4 py-4">
          <div className="thumbnail">
            <div className="thumb-img py-2">
              <img
                src={data.thumbnail}
                width="100%"
                alt="ngiu youtube audio"
              />
            </div>
            <div className="content">
              <div className="clearfix">
                <h3 className="font-semibold mb-1">
                  {data.title}
                </h3>
                <p>{data.author}</p>
                <p className="mag0">{videoDuration}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
