import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Link, Outlet } from "react-router-dom";
import SplashScreen from "./components/FrontEnd/SplashScreen";
import Heart from "./components/FrontEnd/Heart";
import { useCallback, useState } from "react";
import TextTyping from "./components/FrontEnd/TextTyping";
import { Music } from "react-feather";
import classnames from 'classnames'

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const [fingerprintScanSucess, setFingerprintScan] = React.useState(false);
  const [startTyping, setStartTyping] = useState(false);
  const handleScanSucess = useCallback(() => {
    setFingerprintScan(true);
    console.log("scan sucess");
    setTimeout(() => {
      setStartTyping(true);
    }, 2500);
    // handlePlayAudio();
  }, []);

  console.log(fingerprintScanSucess, "fingerprintScanSucess");

  let defaultAudio = `https://ngiu.s3-ap-southeast-1.amazonaws.com/user_assets/100164827319_/audio_src_1675748523`;

  const [playing, setPlaying] = useState(true);

  const handlePlayAudio = useCallback(() => {
    const audio = document.querySelector(".player__audio");
    if (!playing) {
      audio.play();
      setPlaying(true);
    } else {
      setPlaying(false);
      audio.pause();
    }
    setPlaying(!playing);
  });

  return (
    <div className="App">
      <SplashScreen visible={isLoading} scanSucess={handleScanSucess} />
      <Heart sucessAccess={fingerprintScanSucess} />
      <TextTyping
        startTyping={startTyping}
        textMessage={
          "L is for the way you look at me, O is for the only one I see. V is very, very extraordinary. E is even more than anyone that you adore can. Love is all that"
        }
      />
      <Outlet />
      <div id="audio" onClick={handlePlayAudio}>
        {fingerprintScanSucess && (
          <div className={classnames("audio-wrapper", {'mrotate': playing})}>
            <Music />
            {!playing && (
              <div className="icon-cancel">
                <div className="icon-h" />
              </div>
            )}
          </div>
        )}
        <audio className="player__audio viewer">
          <source src={defaultAudio} type="audio/mpeg" data-trackid={1} />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  );
}

export default App;
