import { useCallback, useRef, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import YoutubeInfo from "../components/FrontEnd/YoutubeInfo";
import classnames from "classnames";
import BottomMenu from "../components/UI/BottomMenu";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function MusicPlayer() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token"]);
  const token = cookies.token;

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const getUserInfo = await axios.get(`${SERVER_URL}/user/user-info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(getUserInfo, "getUserInfo");
      if (getUserInfo.status === 200) {
        const dataRes = getUserInfo.data;
        setUserData(dataRes);
        setYoutubeLink(dataRes.youtube_audio_url);
        let fileName = dataRes.audio_file ?? "Chọn File";
        setFileName(fileName);
        // load video youtube thumbnail
        handleGetYoutubeVideoInfo(dataRes.youtube_audio_url);
        const audioSourceTypeDefault = dataRes.youtube_audio_url ? 1 : dataRes.audio_file ? 2 : 1;
        setAudioSourceType(audioSourceTypeDefault);
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
      console.error(error);
    }
  };

  const [message, setMessage] = useState("");
  const warningMessage = "text-yellow-600";
  const inforMessage = "text-yellow-600";
  const successMessage = "text-green-500";
  const [messageCss, setMessageCss] = useState(warningMessage);

  const loadingClass = "btn-loading";
  const [loadingBtn, setLoadingBtn] = useState("");
  const inputFile = useRef(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileName, setFileName] = useState(
    userData.audio_file ? userData.audio_file : "Chọn File"
  );
  const [youtubeLink, setYoutubeLink] = useState(
    userData.youtube_audio_url ?? ""
  );
  const handleChangeYoutubeLink = useCallback((e) => {
    setYoutubeLink(e.target.value);
  }, []);

  const [audioSourceType, setAudioSourceType] = useState(1);
  const handleChangeAudioSourceType = useCallback((value) => {
    setAudioSourceType(value);
  }, []);

  const handleChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;

    if (!file) {
      return;
    }

    if (fileType !== "audio/mpeg") {
      setMessageCss(warningMessage);
      setMessage("File không hỗ trợ, vui lòng chọn đúng định dạng file mp3 ");
      return;
    } else {
      setMessage("");
    }
    // se max file size to 10MB
    const max_file_size = 10000;
    let file_size = event.target.files[0].size;
    file_size = file_size / 1024;

    if (file_size > max_file_size) {
      setMessageCss(warningMessage);
      setMessage("File quá lớn ko thể upload, bạn vui lòng chọn ảnh dưới 10MB");
      return;
    } else {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const uploadAudioInfo = async () => {
    setLoadingBtn(loadingClass);
    let formData = new FormData();
    formData.append("audio_src", selectedFile);
    formData.append("audio_file", fileName);
    let response = await fetch(`${SERVER_URL}/user/upload-audio`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (response.status == 200) {
      let data = await response.json();
      setMessageCss(successMessage);
      setMessage(data.message);
    } else {
      let data = await response.json();
      setMessageCss(warningMessage);
      setMessage(data.message);
    }
    setLoadingBtn("");
  };

  const uploadAudioWithYoutubeLink = async () => {
    setLoadingBtn(loadingClass);
    const dataUpdate = {
        youtube_audio_url: youtubeLink,
    };
    let response = await fetch(
      `${SERVER_URL}/user/upload-audio-info-with-youtube`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataUpdate),
      }
    );

    if (response.status == 200) {
      let data = await response.json();
      setMessageCss(successMessage);
      setMessage(data.message);
    } else {
      let data = await response.json();
      setMessageCss(warningMessage);
      setMessage(data.message);
    }
    setLoadingBtn("");
  };

  const handleSaveInfo = () => {
    setMessage("");
    if (!selectedFile && !userData.audio_src && !isValidYoutubeLink) {
      setMessageCss(warningMessage);
      setMessage("Vui lòng chọn nhạc để upload");
      return;
    }
    if (selectedFile && audioSourceType == 2) {
      uploadAudioInfo();
    }
    if (
      isValidYoutubeLink &&
      audioSourceType == 1 &&
      userData.youtube_audio_url !== youtubeLink
    ) {
      uploadAudioWithYoutubeLink();
    }
  };

  const [youtubeData, setYoutubeData] = useState([]);

  const extractVideoId = (url) => {
    const pattern =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(pattern);
    if (match) {
      return `https://www.youtube.com/watch?v=${match[1]}`;
    }
    return null;
  };

  const [isValidYoutubeLink, setIsValidYoutubeLink] = useState(null);
  const [isValidDuration, setIsValidDuration] = useState(true);

  function validateDuration(duration) {
    // Sử dụng phương thức match() và biểu thức chính quy để tìm kiếm số giờ (H), phút (M) và giây (S) trong chuỗi thời lượng
    const matches = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

    if (matches) {
      const hours = matches[1] ? parseInt(matches[1]) : 0;
      const minutes = matches[2] ? parseInt(matches[2]) : 0;
      const seconds = matches[3] ? parseInt(matches[3]) : 0;

      // Chuyển đổi giờ, phút và giây thành tổng số giây
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      // Kiểm tra nếu tổng số giây nhỏ hơn 600 (10 phút)
      if (totalSeconds < 600) {
        return true;
      }
    }
    return false;
  }

  const handleGetYoutubeVideoInfo = async (youtubeLink) => {
    setIsValidDuration(true);
    if (!youtubeLink) {
      setIsValidYoutubeLink(null);
      return;
    }
    if (extractVideoId(youtubeLink) == null) {
      setIsValidYoutubeLink(false);
      return;
    }
    let response = await fetch(`${SERVER_URL}/user/get-youtube-video-info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ youtube_audio_url: youtubeLink }),
    });

    const dataRes = await response.json();
    const checkValidateDuration = validateDuration(dataRes.duration);
    if (checkValidateDuration) {
      setYoutubeData(dataRes);
      setIsValidYoutubeLink(true);
    } else {
      setYoutubeData([]);
      setIsValidDuration(false);
      setIsValidYoutubeLink(null);
    }
  };

  return (
    <>
      <div className="mb-16">
        <div className="relative bg-white rounded-lg text-gray-900 mb-10">
          <div className="topbar-action-wrapper bg-white z-50">
            <div className="topbar-action justify-between items-center px-4">
              <div className="topbar-action-name font-bold text-center">
                <span>Cài đặt nhạc nền</span>
              </div>
            </div>
          </div>
          <main className="p-3 overflow-y-scroll">
            <div className="mt-2 mb-6">
              <form className="w-full max-w-lg m-auto">
                <div>
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Chọn FILE NHẠC
                  </div>
                  <div className="flex flex-wrap justify-center my-2 choose-source-type">
                    <div
                      onClick={() => handleChangeAudioSourceType(1)}
                      className={classnames(
                        "opacity-40 m-auto bg-blue-500 active:bg-blue-400 text-white text-white font-medium py-2 px-4 rounded",
                        {
                          "selected-source-type shadow ": audioSourceType == 1,
                        }
                      )}
                    >
                      Nhập từ Youtube
                    </div>
                    <div
                      onClick={() => handleChangeAudioSourceType(2)}
                      className={classnames(
                        "opacity-40 m-auto bg-blue-500 active:bg-blue-400 text-white text-white font-medium py-2 px-4 rounded",
                        {
                          "selected-source-type shadow ": audioSourceType == 2,
                        }
                      )}
                    >
                      Upload file mp3
                    </div>
                  </div>
                  <div className="select-audio-content">
                    {audioSourceType == 1 && (
                      <div className="flex flex-wrap justify-center -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Link Youtube
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="https://www.youtube.com/watch?v=..."
                            value={youtubeLink}
                            onChange={handleChangeYoutubeLink}
                            onBlur={() => handleGetYoutubeVideoInfo(youtubeLink)}
                          />
                          {isValidYoutubeLink == false && (
                            <span className="text-yellow-700">
                              Link youtube không hợp lệ, vui lòng thử lại
                            </span>
                          )}
                        </div>
                        {isValidDuration == false && (
                          <small
                            className={
                              "mx-2 block font-normal leading-normal my-2 " +
                              messageCss
                            }
                          >
                            Thời lượng video bạn chọn quá dài, vui lòng chọn
                            video nhạc dưới 10 phút!
                          </small>
                        )}
                        <YoutubeInfo data={youtubeData} />
                      </div>
                    )}

                    {audioSourceType == 2 && (
                      <div className="flex flex-wrap justify-center -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Upload file MP3
                          </label>
                          <label className="w-full flex flex-col items-center px-4 py-3 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
                            <i className="fas fa-cloud-upload-alt fa-3x"></i>
                            <span className="mt-2 text-base leading-normal">
                              {fileName}
                            </span>
                            <input
                              className="hidden"
                              type="file"
                              id="file"
                              ref={inputFile}
                              accept="audio/mp3, audio/wav"
                              onChange={handleChange}
                              autoComplete="off"
                              tabIndex={-1}
                              style={{ display: "none" }}
                            />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <small
                  className={
                    "block font-normal leading-normal my-2 " + messageCss
                  }
                >
                  {message}
                </small>

                <div className="md:flex md:items-center text-center mb-8 pb-8">
                  <button
                    className={
                      "shadow m-auto bg-blue-500 active:bg-blue-400 text-white focus:shadow-outline focus:outline-none text-white font-medium py-2 px-4 rounded " +
                      loadingBtn
                    }
                    type="button"
                    // onClick={handleSaveInfo}
                    onClick={handleSaveInfo}
                  >
                    Lưu
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
      <BottomMenu></BottomMenu>
    </>
  );
}
