import React from "react";
import SplashScreen from "../components/FrontEnd/SplashScreen";
import Heart from "../components/FrontEnd/Heart";
import { useCallback, useState, useEffect } from "react";
import TextTyping from "../components/FrontEnd/TextTyping";
import AudioPlayer from "../components/FrontEnd/AudioPlayer";
import BottomMenu from "../components/UI/BottomMenu";
import { useCookies } from "react-cookie";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useParams } from "react-router-dom";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BUCKET = process.env.REACT_APP_BUCKET;
const REGION = process.env.REACT_APP_REGION;

function App() {
  const { accountNumber } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const [cookies, setCookie] = useCookies(["token"]);
  const token = cookies.token;

  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [audioSrc, setAudioSrc] = useState("");

  const fetchData = async () => {
    try {
      const getUserInfo = await axios.get(`${SERVER_URL}/user/user-info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (getUserInfo.status === 200) {
        const dataRes = getUserInfo.data;
        setUserData(dataRes);
        setTextMessage(dataRes.message);
        setTitle(dataRes.title);
        let userImage = `https://${BUCKET}.s3-${REGION}.amazonaws.com/user_assets/${dataRes.account_number}/${dataRes.image}`;
        setImage(userImage);
        setSubtitle(dataRes.subtitle);

        let userAudioSrc = `https://${BUCKET}.s3-${REGION}.amazonaws.com/user_assets/${dataRes.account_number}/${dataRes.audio_src}`;
        setAudioSrc(userAudioSrc);
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
      console.error(error);
    }
  };

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const [fingerprintScanSucess, setFingerprintScan] = React.useState(false);
  const [startTyping, setStartTyping] = useState(false);
  const handleScanSucess = useCallback(() => {
    setFingerprintScan(true);
    console.log("scan sucess");
    setTimeout(() => {
      setStartTyping(true);
    }, 2500);
  });

  console.log(fingerprintScanSucess, "fingerprintScanSucess");

  return (
    <div className="Preview">
      <SplashScreen visible={isLoading} scanSucess={handleScanSucess} />
      <Heart
        sucessAccess={fingerprintScanSucess}
        title={title}
        subtitle={subtitle}
        innerHeartImg={image}
      />
      <TextTyping startTyping={startTyping} textMessage={textMessage} />
      <AudioPlayer
        fingerprintScanSucess={fingerprintScanSucess}
        audioSrc={audioSrc}
      />
      <BottomMenu></BottomMenu>
    </div>
  );
}

export default App;
