import React, { useState, useEffect, useCallback } from "react";
import "./frontend.css";
import $ from "jquery";

export default function Heart({ sucessAccess, ratio = 2, title = 'L O V E', subtitle = 'CHARGING STATION', innerHeartImg }) {
  var settings = {
    particles: {
      length: 500,

      duration: 2,

      velocity: 100,

      effect: -0.75,

      size: 30,
    },
  };
  /*
 * Point class
 */

  var Point = (function () {
    function Point(x, y) {
      this.x = typeof x !== "undefined" ? x : 0;

      this.y = typeof y !== "undefined" ? y : 0;
    }

    Point.prototype.clone = function () {
      return new Point(this.x, this.y);
    };

    Point.prototype.length = function (length) {
      if (typeof length == "undefined")
        return Math.sqrt(this.x * this.x + this.y * this.y);

      this.normalize();

      this.x *= length;

      this.y *= length;

      return this;
    };

    Point.prototype.normalize = function () {
      var length = this.length();

      this.x /= length;

      this.y /= length;

      return this;
    };

    return Point;
  })();

  /*

 * Particle class

 */

  var Particle = (function () {
    function Particle() {
      this.position = new Point();

      this.velocity = new Point();

      this.acceleration = new Point();

      this.age = 0;
    }

    Particle.prototype.initialize = function (x, y, dx, dy) {
      this.position.x = x;

      this.position.y = y;

      this.velocity.x = dx;

      this.velocity.y = dy;

      this.acceleration.x = dx * settings.particles.effect;

      this.acceleration.y = dy * settings.particles.effect;

      this.age = 0;
    };

    Particle.prototype.update = function (deltaTime) {
      this.position.x += this.velocity.x * deltaTime;

      this.position.y += this.velocity.y * deltaTime;

      this.velocity.x += this.acceleration.x * deltaTime;

      this.velocity.y += this.acceleration.y * deltaTime;

      this.age += deltaTime;
    };

    Particle.prototype.draw = function (context, image) {
      function ease(t) {
        return --t * t * t + 1;
      }

      var size = image.width * ease(this.age / settings.particles.duration);

      context.globalAlpha = 1 - this.age / settings.particles.duration;

      context.drawImage(
        image,
        this.position.x - size / 2,
        this.position.y - size / 2,
        size,
        size
      );
    };

    return Particle;
  })();

  /*

 * ParticlePool class

 */

  var ParticlePool = (function () {
    var particles,
      firstActive = 0,
      firstFree = 0,
      duration = settings.particles.duration;

    function ParticlePool(length) {
      // create and populate particle pool

      particles = new Array(length);

      for (var i = 0; i < particles.length; i++) particles[i] = new Particle();
    }

    ParticlePool.prototype.add = function (x, y, dx, dy) {
      particles[firstFree].initialize(x, y, dx, dy);

      // handle circular queue

      firstFree++;

      if (firstFree == particles.length) firstFree = 0;

      if (firstActive == firstFree) firstActive++;

      if (firstActive == particles.length) firstActive = 0;
    };

    ParticlePool.prototype.update = function (deltaTime) {
      var i;

      // update active particles

      if (firstActive < firstFree) {
        for (i = firstActive; i < firstFree; i++)
          particles[i].update(deltaTime);
      }

      if (firstFree < firstActive) {
        for (i = firstActive; i < particles.length; i++)
          particles[i].update(deltaTime);

        for (i = 0; i < firstFree; i++) particles[i].update(deltaTime);
      }

      // remove inactive particles

      while (
        particles[firstActive].age >= duration &&
        firstActive != firstFree
      ) {
        firstActive++;

        if (firstActive == particles.length) firstActive = 0;
      }
    };

    ParticlePool.prototype.draw = function (context, image) {
      // draw active particles
      var i;

      if (firstActive < firstFree) {
        for (i = firstActive; i < firstFree; i++)
          particles[i].draw(context, image);
      }

      if (firstFree < firstActive) {
        for (i = firstActive; i < particles.length; i++)
          particles[i].draw(context, image);

        for (i = 0; i < firstFree; i++) particles[i].draw(context, image);
      }
    };
    return ParticlePool;
  })();


  useEffect(() => {
    (function () {
      var b = 0;
      var c = ["ms", "moz", "webkit", "o"];
      for (var a = 0; a < c.length && !window.requestAnimationFrame; ++a) {
        window.requestAnimationFrame = window[c[a] + "RequestAnimationFrame"];
        window.cancelAnimationFrame =
          window[c[a] + "CancelAnimationFrame"] ||
          window[c[a] + "CancelRequestAnimationFrame"];
      }
      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (h, e) {
          var d = new Date().getTime();
          var f = Math.max(0, 16 - (d - b));
          var g = window.setTimeout(function () {
            h(d + f);
          }, f);
          b = d + f;
          return g;
        };
      }
      if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = function (d) {
          clearTimeout(d);
        };
      }
    })();

    /*

 * Show image

 */

  }, []);

  const showImage = (imageHeart) => {
    let baseOpacity = 0;
    const opaInterval = setInterval(() => {
      if (baseOpacity < 90) {
        baseOpacity++;
      } else {
        clearInterval(opaInterval);
      }
      // document.getElementById(
      //   "inner-hert-img"
      // ).style.opacity = `${baseOpacity}%`;
      imageHeart.style.opacity = `${baseOpacity}%`;
    }, 2000);
  }

  // setTimeout(() => {
  //   showImage(document.getElementById("inner-hert-img"));
  // }, 3000);


  let runCreateHeartAnimation = function (canvas) {
    var context = canvas.getContext("2d"),
      particles = new ParticlePool(settings.particles.length),
      particleRate = settings.particles.length / settings.particles.duration, // particles/sec
      time;

    // get point on heart with -PI <= t <= PI

    function pointOnHeart(t) {
      return new Point(
        160 * Math.pow(Math.sin(t), 3),

        130 * Math.cos(t) -
          50 * Math.cos(2 * t) -
          20 * Math.cos(3 * t) -
          10 * Math.cos(4 * t) +
          25
      );
    }

    // creating the particle image using a dummy canvas

    var image = (function () {
      var canvas = document.createElement("canvas"),
        context = canvas.getContext("2d");

      canvas.width = settings.particles.size;

      canvas.height = settings.particles.size;

      // helper function to create the path

      function to(t) {
        var point = pointOnHeart(t);

        point.x =
          settings.particles.size / 2 +
          (point.x * settings.particles.size) / 350;

        point.y =
          settings.particles.size / 2 -
          (point.y * settings.particles.size) / 350;

        return point;
      }

      // create the path

      context.beginPath();

      var t = -Math.PI;

      var point = to(t);

      context.moveTo(point.x, point.y);

      while (t < Math.PI) {
        t += 0.01; // baby steps!

        point = to(t);

        context.lineTo(point.x, point.y);
      }

      context.closePath();

      // create the fill

      context.fillStyle = "#ea80b0";

      context.fill();

      // create the image

      var image = new Image();

      image.src = canvas.toDataURL();

      return image;
    })();

    // render that thing!

    function render() {
      // next animation frame

      requestAnimationFrame(render);

      // update time

      var newTime = new Date().getTime() / 1000,
        deltaTime = newTime - (time || newTime);

      time = newTime;

      // clear canvas

      context.clearRect(0, 0, canvas.width, canvas.height);

      // create new particles

      var amount = particleRate * deltaTime;

      for (var i = 0; i < amount; i++) {
        var pos = pointOnHeart(Math.PI - 2 * Math.PI * Math.random());

        var dir = pos.clone().length(settings.particles.velocity);

        particles.add(
          canvas.width / 2 + pos.x,
          canvas.height / 2 - pos.y,
          dir.x,
          -dir.y
        );
      }

      // update and draw particles

      particles.update(deltaTime);

      particles.draw(context, image);
    }

    // handle (re-)sizing of the canvas

    function onResize() {
      canvas.width = canvas.clientWidth * ratio;

      canvas.height = canvas.clientHeight * ratio;
    }

    window.onresize = onResize;

    // delay rendering bootstrap

    // setTimeout(function () {
    onResize();
    render();
    // }, 10000);
    // }(document.getElementById("heart_canvas"));
  };

  if (sucessAccess) {
    setTimeout(()=>{
      runCreateHeartAnimation(document.getElementById("heart_canvas"));
      showImage(document.getElementById("inner-hert-img"));
    }, 500)
  }

  useEffect(() => {
    function randomValue(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    $(document).ready(function () {
      var perc = 50;
      var s = document.styleSheets[0];
      // Define a size array, this will be used to vary bubble sizes
      var sArray = [];

      setTimeout(function () {
        updateBattery(perc, s);
      }, 500);

      function updateBattery(perc, s) {
        if (perc === 100) {
          sArray = [];
        } else if (sArray.length === 0) {
          sArray = [4, 6, 8, 10];
        }
      }

      // setInterval function used to create new bubble every 350 milliseconds
      setInterval(function () {
        if (sArray.length > 0) {
          // Get a random size, defined as variable so it can be used for both width and height
          var size = randomValue(sArray);

          var largestSize = Math.max.apply(Math, sArray);
          var offset = largestSize / 2; // half to get the largest bubble radius
          offset += 5; // 5px for border-right

          // New bubble appended to div with it's size and left position being set inline
          $(".bubbles").each(function () {
            var bArray = new Array(parseInt($(this).width()) - offset)
              .join()
              .split(",")
              .map(function (item, index) {
                return ++index;
              });

            $(this).append(
              '<div class="individual-bubble" style="left: ' +
                randomValue(bArray) +
                "px; width: " +
                size +
                "px; height: " +
                size +
                'px"></div>'
            );
          });

          // Animate each bubble to the top (bottom 100%) and reduce opacity as it moves
          // Callback function used to remove finished animations from the page
          $(".individual-bubble").animate(
            {
              top: 0,
              bottom: "100%",
              opacity: "-=0.7",
            },
            3000,
            function () {
              $(this).remove();
            }
          );
        }
      }, 350);
    });
  }, []);


  return (
    <>
      <div className="love-title love-content">
        {/* EDIT HERE */}
        <h1 className="title">{title}</h1>
        <h3 className="subtitle">
        {subtitle}
        </h3>
      </div>
      <div className="main-container">
        <img
          id="inner-hert-img"
          className="lover-img"
          src={innerHeartImg}
          alt="lover image"
        />
        <canvas id="heart_canvas" />
        <div className="sub-container">
          <div className="sky">
            <div className="stars" />
            <div className="stars2" />
            <div className="stars3" />
            <div className="comet" />
          </div>
        </div>
      </div>
      <div className="bottom-charging">
        <div className="bubbles bottom-bubbles">
          <span>&nbsp;</span>
        </div>
      </div>
    </>
  );
}
