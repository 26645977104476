import { useCallback, useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import BottomMenu from "../components/UI/BottomMenu";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BUCKET = process.env.REACT_APP_BUCKET;
const REGION = process.env.REACT_APP_REGION;

export default function Settings() {
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate.goBack();
  }, []);

  const [cookies, setCookie] = useCookies(["token"]);
  const token = cookies.token;

  console.log(token, "token");

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const getUserInfo = await axios.get(`${SERVER_URL}/user/user-info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(getUserInfo, "getUserInfo");
      if (getUserInfo.status === 200) {
        const dataRes = getUserInfo.data;
        setUserData(dataRes);
        setTile(dataRes.title);
        setSubtitle(dataRes.subtitle);
        setContent(dataRes.message);
        let imageS3Url = `https://${BUCKET}.s3-${REGION}.amazonaws.com/user_assets/${dataRes.account_number}/${dataRes.image}`;
        setSelectedImageUrl(imageS3Url);
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
      console.error(error);
    }
  };

  console.log(userData, "userData");

  const [isLoading, setIsLoading] = useState("");
  const [message, setMessage] = useState("");
  const warningMessage = "text-yellow-600";
  const successMessage = "text-green-500";
  const [messageCss, setMessageCss] = useState(warningMessage);

  let imageS3Url = `https://${BUCKET}.s3-${REGION}.amazonaws.com/user_assets/${userData.account_number}/${userData.image}`;

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(
    userData.image ? imageS3Url : ""
  );

  const [selectFileMessage, setSelectFileMessage] = useState(
    "Không có file nào được chọn"
  );

  const [image, setImage] = useState(selectedImageUrl);
  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      cropper.getCroppedCanvas({ maxWidth: 600 }).toBlob((blob) => {
        const ImageUrl = URL.createObjectURL(blob);
        setSelectedImageUrl(ImageUrl);
        setSelectedImage(blob);
        setShowEditCoverMediaModal(false);
      }, "image/jpeg");
    }
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    if (!file) {
      return;
    }
    if (fileType !== "image/jpeg" && fileType !== "image/png") {
      setMessageCss(warningMessage);
      setMessage(
        "File không hỗ trợ, vui lòng chọn đúng định dạng file ảnh (jpg, png, jpeg)"
      );
      return;
    } else {
      setMessage("");
    }
    // se max file size to 6MB
    const max_file_size = 6000;
    let file_size = event.target.files[0].size;
    file_size = file_size / 1024;

    if (file_size > max_file_size) {
      setMessageCss(warningMessage);
      setMessage("File quá lớn ko thể upload, bạn vui lòng chọn ảnh dưới 6MB");
      return;
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setSelectFileMessage("Đã chọn file: " + file.name);
      setShowEditCoverMediaModal(true);
    }
  };

  const handleSave = async () => {
    setIsLoading("btn-loading");
    let dataUser = JSON.stringify({
      title: title,
      subtitle: subtitle,
      message: content,
    });

    let authReq = await fetch(`${SERVER_URL}/user/update-config`, {
      method: "POST",
      body: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    let uploadAvatar = null;
    if (selectedImage) {
      uploadFile(selectedImage);
      uploadAvatar = await uploadFile(selectedImage);
    }

    if (uploadAvatar !== null && uploadAvatar.status !== 200) {
      setMessageCss(warningMessage);
      setMessage(
        "Có lỗi sảy ra, bạn vui lòng thử lại sau hoặc liên hệ bộ phận kỹ thuật để được hỗ trợ"
      );
    }
    if (authReq.status !== 200) {
      setMessageCss(warningMessage);
      setMessage(
        "Có lỗi sảy ra, bạn vui lòng thử lại sau hoặc liên hệ bộ phận kỹ thuật để được hỗ trợ"
      );
    }
    setMessageCss(successMessage);
    setMessage("Đã cập nhật thông tin thành công");
    setIsLoading("");
  };

  const uploadFile = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    let response = await fetch(`${SERVER_URL}/user/upload-image`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    return response;
  };

  const [title, setTile] = useState(userData.title ?? "");
  const [content, setContent] = useState(userData.message ?? "");
  const [subtitle, setSubtitle] = useState(userData.subtitle ?? "");

  const handleChangeTitle = useCallback((e) => setTile(e.target.value), []);
  const handleChangeSubtitle = useCallback(
    (e) => setSubtitle(e.target.value),
    []
  );
  const handleChangeContent = useCallback(
    (e) => setContent(e.target.value),
    []
  );

  const [showEditCoverMediaModal, setShowEditCoverMediaModal] = useState(false);
  const handleCancelEditCoverMedia = useCallback(() => {
    setShowEditCoverMediaModal(false);
  }, [showEditCoverMediaModal]);

  const modalCoverImg = (
    <div className="fixed inset-0 w-full h-full z-50 bg-black bg-opacity-50 duration-300 overflow-y-auto">
      <div className="relative sm:w-full md:w-3/4 lg:w-1/2 md:mx-8 sm:mx-auto mt-28 mb-24 opacity-100">
        <div className="relative bg-white shadow-lg rounded-lg text-gray-900 z-20">
          <div className="topbar-action border-b flex justify-between items-center px-4 py-2">
            <div
              onClick={handleCancelEditCoverMedia}
              className="topbar-action-back cursor-pointer w-8"
            >
              <i className="text-blue-500 fas fa-arrow-left"></i>
            </div>
            <div className="topbar-action-name font-bold">
              <span>Xem Trước</span>
            </div>
            <div className="topbar-action-save">
              <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={getCropData}
              >
                Áp dụng
              </button>
            </div>
          </div>
          <main className="p-3 flex mt-10 text-center overflow-y-auto">
            <div className="m-auto pb-10">
              <Cropper
                style={{
                  height: "auto",
                  width: "auto",
                  maxWidth: 400,
                  maxHeight: 400,
                }}
                zoomTo={0}
                initialAspectRatio={1 / 1}
                src={image}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                aspectRatio={1 / 1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="flex-wrap max-w-3xl m-auto pb-16">
        <div className="topbar-action-wrapper bg-white z-50">
          <div className="topbar-action flex justify-between items-center px-4 py-2">
            <div className="topbar-action-back cursor-pointer w-8"></div>
            <div className="topbar-action-name font-bold">
              <span>Cài đặt nội dung hiển thị</span>
            </div>
            <div className="topbar-action-save">
              <button
                className={
                  "bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 " +
                  isLoading
                }
                type="button"
                onClick={handleSave}
              >
                Lưu
              </button>
            </div>
          </div>
        </div>

        <div className="block font-normal leading-normal mb-4 text-yellow-600">
          <p
            className={
              "px-4 block font-normal leading-normal mb-4 " + messageCss
            }
          >
            {message}
          </p>
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tiêu đề 1
              </label>
              <input
                type="text"
                className="px-3 py-3 border placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                value={title}
                onChange={handleChangeTitle}
              />
            </div>
          </div>
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                Tiêu đề 2
              </label>
              <input
                type="text"
                className="px-3 py-3 border placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                value={subtitle}
                onChange={handleChangeSubtitle}
              />
            </div>
          </div>
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                Lời nhắn
              </label>
              <small
                className={
                  "block font-normal leading-normal my-2 text-blue-500"
                }
              >
                Hãy gửi lời nói bạn muốn nhắn nhủ tới người ấy, đoạn chữ này sẽ
                được hiển thị khi bản nhạc được phát, hãy phân cách từng câu
                bằng dấu chấm
              </small>
              <textarea
                onChange={handleChangeContent}
                value={content}
                rows="4"
                placeholder="Nhập lời nhắn của bạn"
                className="resize-y border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              ></textarea>
            </div>
          </div>

          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                Ảnh
              </label>
              <div className="">
                <div className="mb-2">
                  {" "}
                  {selectedImageUrl.length > 0 && (
                    <img className="w-32 my-4" src={selectedImageUrl} />
                  )}
                  <div className="flex justify-between items-center text-gray-400">
                    {" "}
                    <span>{selectFileMessage}</span>{" "}
                  </div>
                  <div className="relative h-20 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                    <div className="absolute">
                      <div className="flex flex-col items-center ">
                        {" "}
                        <i className="fa fa-cloud-upload fa-3x text-gray-200" />{" "}
                        <span className="block text-blue-400 font-normal">
                          Chọn ảnh
                        </span>{" "}
                      </div>
                    </div>{" "}
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={handleChange}
                      autoComplete="off"
                      className="h-full w-full opacity-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditCoverMediaModal && modalCoverImg}
      <BottomMenu></BottomMenu>
    </>
  );
}
