import Typewriter from "typewriter-effect";

export default function TextTyping({ startTyping, textMessage }) {
  if (!startTyping) {
    return <></>
  }
  
  const textData = textMessage.split(".");
  
  return (
    <>
      <div className="text-typing-wrapper">
        <div className="typewrite">
          <Typewriter
            options={{
              strings: textData,
              autoStart: true,
              loop: true,
              deleteSpeed: 30
            }}
          />
        </div>
      </div>
    </>
  );
}
