import { useCallback, useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import BottomMenu from "../components/UI/BottomMenu";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BUCKET = process.env.REACT_APP_BUCKET;
const REGION = process.env.REACT_APP_REGION;

export default function Settings() {
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate.goBack();
  }, []);

  const [cookies, setCookie] = useCookies(["token"]);
  const token = cookies.token;

  console.log(token, "token");

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const getUserInfo = await axios.get(`${SERVER_URL}/user/user-info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(getUserInfo, "getUserInfo");
      if (getUserInfo.status === 200) {
        const dataRes = getUserInfo.data;
        setUserData(dataRes);
        setTile(dataRes.title);
        setSubtitle(dataRes.subtitle);
        setContent(dataRes.message);
        let imageS3Url = `https://${BUCKET}.s3-${REGION}.amazonaws.com/user_assets/${dataRes.account_number}/${dataRes.image}`;
        setSelectedImageUrl(imageS3Url);
      } else {
        navigate('/login');
      }
    } catch (error) {
      navigate('/login');
      console.error(error);
    }
  };

  console.log(userData, "userData");

  const [isLoading, setIsLoading] = useState("");
  const [message, setMessage] = useState("");
  const warningMessage = "text-yellow-600";
  const successMessage = "text-green-500";
  const [messageCss, setMessageCss] = useState(warningMessage);

  let imageS3Url = `https://${BUCKET}.s3-${REGION}.amazonaws.com/user_assets/${userData.account_number}/${userData.image}`;

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(
    userData.image ? imageS3Url : ""
  );

  const [selectFileMessage, setSelectFileMessage] = useState(
    "Không có file nào được chọn"
  );

  const [image, setImage] = useState(selectedImageUrl);
  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      cropper.getCroppedCanvas({ maxWidth: 600 }).toBlob((blob) => {
        const ImageUrl = URL.createObjectURL(blob);
        setSelectedImageUrl(ImageUrl);
        setSelectedImage(blob);
        setShowEditCoverMediaModal(false);
      }, "image/jpeg");
    }
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    if (!file) {
      return;
    }
    if (fileType !== "image/jpeg" && fileType !== "image/png") {
      setMessageCss(warningMessage);
      setMessage(
        "File không hỗ trợ, vui lòng chọn đúng định dạng file ảnh (jpg, png, jpeg)"
      );
      return;
    } else {
      setMessage("");
    }
    // se max file size to 6MB
    const max_file_size = 6000;
    let file_size = event.target.files[0].size;
    file_size = file_size / 1024;

    if (file_size > max_file_size) {
      setMessageCss(warningMessage);
      setMessage("File quá lớn ko thể upload, bạn vui lòng chọn ảnh dưới 6MB");
      return;
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setSelectFileMessage("Đã chọn file: " + file.name);
      setShowEditCoverMediaModal(true);
    }
  };

  const handleSave = async () => {
    setIsLoading("btn-loading");
    let dataUser = JSON.stringify({
      title: title,
      subtitle: subtitle,
      message: content,
    });

    let authReq = await fetch(`${SERVER_URL}/user/update-config`, {
      method: "POST",
      body: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    let uploadAvatar = null;
    if (selectedImage) {
      uploadFile(selectedImage);
      uploadAvatar = await uploadFile(selectedImage);
    }

    if (uploadAvatar !== null && uploadAvatar.status !== 200) {
      setMessageCss(warningMessage);
      setMessage(
        "Có lỗi sảy ra, bạn vui lòng thử lại sau hoặc liên hệ bộ phận kỹ thuật để được hỗ trợ"
      );
    }
    if (authReq.status !== 200) {
      setMessageCss(warningMessage);
      setMessage(
        "Có lỗi sảy ra, bạn vui lòng thử lại sau hoặc liên hệ bộ phận kỹ thuật để được hỗ trợ"
      );
    }
    setMessageCss(successMessage);
    setMessage("Đã cập nhật thông tin thành công");
    setIsLoading("");
  };

  const uploadFile = async (img) => {
    let formData = new FormData();
    formData.append("image", img);
    let response = await fetch(`${SERVER_URL}/user/upload-image`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    return response;
  };

  const [title, setTile] = useState(userData.title ?? "LOVE");
  const [content, setContent] = useState(userData.message ?? "");
  const [subtitle, setSubtitle] = useState(
    userData.subtitle ?? "CHARGING STATION"
  );

  const handleChangeTitle = useCallback((e) => setTile(e.target.value), []);
  const handleChangeSubtitle = useCallback(
    (e) => setSubtitle(e.target.value),
    []
  );
  const handleChangeContent = useCallback(
    (e) => setContent(e.target.value),
    []
  );

  const [showEditCoverMediaModal, setShowEditCoverMediaModal] = useState(false);
  const handleCancelEditCoverMedia = useCallback(() => {
    setShowEditCoverMediaModal(false);
  }, [showEditCoverMediaModal]);

  const modalCoverImg = (
    <div className="fixed inset-0 w-full h-full z-50 bg-black bg-opacity-50 duration-300 overflow-y-auto">
      <div className="relative sm:w-full md:w-3/4 lg:w-1/2 md:mx-8 sm:mx-auto mt-28 mb-24 opacity-100">
        <div className="relative bg-white shadow-lg rounded-lg text-gray-900 z-20">
          <div className="topbar-action border-b flex justify-between items-center px-4 py-2">
            <div
              onClick={handleCancelEditCoverMedia}
              className="topbar-action-back cursor-pointer w-8"
            >
              <i className="text-blue-500 fas fa-arrow-left"></i>
            </div>
            <div className="topbar-action-name font-bold">
              <span>Xem Trước</span>
            </div>
            <div className="topbar-action-save">
              <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={getCropData}
              >
                Áp dụng
              </button>
            </div>
          </div>
          <main className="p-3 flex mt-10 text-center overflow-y-auto">
            <div className="m-auto pb-10">
              <Cropper
                style={{
                  height: "auto",
                  width: "auto",
                  maxWidth: 400,
                  maxHeight: 400,
                }}
                zoomTo={0}
                initialAspectRatio={1 / 1}
                src={image}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                aspectRatio={1 / 1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );


  const handleLogOut = (e) => {
    // (e) => e.preventDefault();
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = "userId=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    navigate('/login');
  }

  const googleForm =
    "https://docs.google.com/forms/d/e/1FAIpQLSffjATqaPvcWPnP3BUc3WOFzk5k3VXXDMtHcnMx-bchscRC8A/viewform?usp=sf_link";

  const supportOnline = "https://tappi.vn/ngiu_support";

  return (
    <>
      
      <div className="app-menu-option px-2 py-2">
          <div className="menu-option-header font-bold text-3xl mb-4 mt-2">
            Menu
          </div>
          <div className="list-view border-b mt-2 text-lg">
            <div className="list-view-header font-bold border-b py-4 px-2">
              Trợ giúp & Hỗ trợ
            </div>
            <div className="list-item flex py-2 px-2 items-center">
              <div className="menu-icon mr-3">
                <i className="far fa-life-ring text-2xl text-gray-700"></i>
              </div>
              <div className="menu-text border-b py-3 w-full font-semibold">
                <a href={supportOnline} target="_blank">
                  Trung tâm trợ giúp
                </a>
              </div>
            </div>
            <div className="list-item flex py-2 px-2 items-center">
              <div className="menu-icon mr-3">
                <i className="fas fa-exclamation-triangle text-2xl text-gray-700"></i>
              </div>
              <div className="menu-text border-b py-3 w-full font-semibold">
                <a href={googleForm} target="_blank">
                  Báo lỗi hệ thống
                </a>
              </div>
            </div>
            <div className="list-item flex py-2 px-2 items-center">
              <div className="menu-icon mr-3">
                <i className="fas fa-rocket text-2xl text-gray-700"></i>
              </div>
              <div className="menu-text py-3 w-full font-semibold">
                <a href={googleForm} target="_blank">
                  Đóng góp ý kiến
                </a>
              </div>
            </div>
          </div>
          <div className="list-view  mt-2 text-lg">
            {/* <div className="list-view-header font-bold border-b py-4 px-2">
              Cài đặt & Hiển thị
            </div>
            <div className="list-item flex py-2 px-2 items-center">
              <div className="menu-icon mr-3">
                <i className="fas fa-cogs text-2xl text-gray-700"></i>
              </div>
              <div className="menu-text border-b py-3 w-full font-semibold">
                <a href="/settings/general">
                  <a href="#">Cài đặt</a>
                </a>
              </div>
            </div> */}
          </div>
          <div
            onClick={handleLogOut}
            className="list-view cursor-pointer font-bold py-4 px-2 mt-1 items-center flex "
          >
            <i className="fas fa-sign-out-alt text-2xl text-gray-700 mr-2"></i>
            Đăng xuất
          </div>
        </div>
      <BottomMenu></BottomMenu>
    </>
  );
}
